export default class Global {

// static IMAGE_URL = "http://localhost/bigdata_apis/bigdata_web_apis/";
// static BASE_URL = "http://localhost/bigdata_apis/bigdata_web_apis/api/";



static IMAGE_URL = "https://pilotaprowebapi.ecommercevolution.shop/"; 
static BASE_URL = "https://pilotaprowebapi.ecommercevolution.shop/api/";
static COMMON_BASE_URL = "https://commonsuperadmin.ecommercevolution.shop/api/";


// static COMMON_BASE_URL = "http://localhost/bigdata_common_super_admin/common_super_admin/api/";
//  static COMMON_BASE_URL = "https://commonsuperadmin.ecommercevolution.shop/api/";

//  static IMAGE_URL = "https://webapisnext.ecommercevolution.shop/";
//  static BASE_URL = "https://webapisnext.ecommercevolution.shop/api/";

static BANNER_THUMBNAIL = "thumbnail/banner.jpg";
static SLIDER_THUMBNAIL = "thumbnail/slider.jpg";
static CATEGORY_THUMBNAIL = "thumbnail/category.jpg";


//static REGISTRATION_URI = "register";
  static LOGIN_URI = "login"
  static HOMEPAGE_GET_ALL_DATE_URI = 'homepage/get_all_data';
  static HOMEPAGE_GET_PRODUCT_SALES_BY_YEAR_URI = 'homepage/product_sales_data_by_year';
  static HOMEPAGE_GET_APP_DOWNLOADS_BY_YEAR_URI = 'homepage/app_downloaded_by_year';
  static HOMEPAGE_GET_FIDELITY_CARD_BY_YEAR_URI = 'homepage/fidelity_card_by_year';
  static HOMEPAGE_GET_FIDELITY_CARD_REQUEST_BY_YEAR_URI = 'homepage/fidelity_card_request_by_year';
  static HOMEPAGE_GET_FIDELITY_CARD_MALE_FEMALE_BY_YEAR_URI = 'homepage/fidelity_card_male_female_by_month_year';
  static HOMEPAGE_GET_PRODUCT_INFO_BY_YEAR_URI = 'homepage/product_info_by_year';
  static HOMEPAGE_GET_LAST_MINUTE_OFFER_BY_YEAR_URI = 'homepage/last_minute_offer_by_year';
  static HOMEPAGE_GET_INSTITUTIONAL_FLYERS_BY_YEAR_URI = 'homepage/institutional_flyers_by_year';
  static HOMEPAGE_GET_SALES_POINTS_BY_YEAR_URI = 'homepage/sales_point_by_year';
  static HOMEPAGE_GET_NEWS_BY_YEAR_URI = 'homepage/news_by_year';
  static HOMEPAGE_GET_TICKETS_BY_YEAR_URI = 'homepage/tickets_by_year';
  static HOMEPAGE_GET_EVENTS_BY_YEAR_URI = 'homepage/events_by_year';
  static HOMEPAGE_GET_COMMUNICATION_BY_YEAR_URI = 'homepage/communication_by_year';
  static HOMEPAGE_GET_FIDELITY_CARD_ON_SALES_POINT_BY_YEAR_URI = 'homepage/fidelity_card_on_sales_point_by_year';
  static HOMEPAGE_GET_FIDELITY_CARD_ON_PROVINCE_BY_YEAR_URI = 'homepage/fidelity_card_on_province_list_by_year';
  static HOMEPAGE_GET_FIDELITY_CARD_ON_FAMILY_MEMBER_BY_YEAR_URI = 'homepage/fidelity_card_on_family_member_by_year';

  static HOMEPAGE_GET_ALL_PRODUCT_DATA_URI = 'homepage/get_all_product_data'; 
  static HOMEPAGE_GET_GET_COUPON_ORDERS_BY_MONTH_YEAR_URI = 'homepage/customer_order_is_coupon_by_month_year';

  static HOMEPAGE_GET_ALL_ORDER_DATA_URI = 'homepage/get_all_order_data';
  static HOMEPAGE_GET_ORDERS_BY_YEAR_AND_STATUS_URI = "homepage/order_list_based_on_status_year";
  static HOMEPAGE_GET_ORDER_BY_YEAR_URI = "homepage/order_list_based_on_year";
  static HOMEPAGE_GET_ORDER_BY_YEAR_MONTH_URI = "homepage/order_list_based_on_year_month";
  static HOMEPAGE_GET_ORDER_VALUE_BY_YEAR_URI = "homepage/order_value_based_on_year";
  static HOMEPAGE_GET_ORDER_VALUE_BY_YEAR_MONTH_URI = "homepage/order_value_based_on_year_month";
  static HOMEPAGE_GET_ORDER_TOTAL_ITEM_COUPON_BY_YEAR_URI = "homepage/order_total_item_coupon_based_on_year";
  static HOMEPAGE_GET_ORDER_COUNT_PER_VALUE_BY_YEAR_MONTH_URI = "homepage/order_count_based_on_values_year_month";
  static HOMEPAGE_GET_ORDER_TOTAL_VALUE_BY_YEAR_MONTH_URI = "homepage/order_value_only_in_entire_year";
  static HOMEPAGE_GET_ORDER_COUNT_PER_ITEM_NO_BY_YEAR_URI = 'homepage/order_count_per_item_no_based_on_year_month';
  static HOMEPAGE_GET_ORDER_COUNT_PER_SLOT_BY_YEAR_URI = 'homepage/order_count_per_slot_based_on_year';
  static HOMEPAGE_GET_ORDER_COUNT_PER_SLOT_BY_SALES_POINT_CODE_URI = 'homepage/order_count_per_slot_based_on_sales_code';
  static HOMEPAGE_GET_ORDER_COUNT_PER_SLOT_BY_YEAR_FOR_STORE_URI = 'homepage/order_count_per_slot_based_on_year_for_store_delv';
  static HOMEPAGE_GET_ORDER_COUNT_PER_SLOT_BY_SALES_POINT_CODE_FOR_STORE_URI = 'homepage/order_count_per_slot_based_on_sales_code_for_store_delv';

  static HOMEPAGE_GET_ALL_CUSTOMER_DATA_URI = 'homepage/get_all_customer_data';


  static HOMEPAGE_GET_TOTAL_ORDER_BY_YEAR_MONTH_URI = "homepage/total_order_based_on_year_month";
  static HOMEPAGE_GET_TOTAL_ORDER_VALUES_BY_YEAR_MONTH_URI = "homepage/total_order_values_based_on_year_month";
  static HOMEPAGE_GET_TOTAL_ORDER_CASH_PAY_BY_YEAR_MONTH_URI = "homepage/total_cash_on_order_based_on_year_month";
  static HOMEPAGE_GET_TOTAL_ORDER_CARD_PAY_BY_YEAR_MONTH_URI = "homepage/total_card_on_order_based_on_year_month";
  static HOMEPAGE_GET_TOTAL_ORDER_PAYPAL_BY_YEAR_MONTH_URI = "homepage/total_paypal_on_order_based_on_year_month";
  static HOMEPAGE_GET_TOTAL_ORDER_HOME_DEV_BY_YEAR_MONTH_URI = "homepage/total_home_delv_on_order_based_on_year_month";
  static HOMEPAGE_GET_TOTAL_ORDER_COLLECT_STORE_BY_YEAR_MONTH_URI = "homepage/total_collect_store_on_order_based_on_year_month";

  static HOMEPAGE_DATA_EXPORT = "homepage/home_data_export";
  static SHOW_HOMEPAGE_EXPORT_BUTTON = "homepage/show_home_excel_export_button";

  static EVENTS_LIST_URI = "event/all"
  static EVENT_ONGOING_GET_INFO_URI = "event/ongoing"
  static EVENT_UPCOMING_GET_INFO_URI = "event/upcoming"
  static EVENT_POST_URI = "event/add"
  static EVENT_GET_INFO_URI = "event/info/"
  static EVENT_DELETE_URI = "event/delete/"
  static EVENT_UPDATE_URI = "event/update/"
  static MESSAGE_POST_URI = "communication/add"
  static MESSAGE_LIST_URI = "communication/all/messages"
  static MESSAGE_DELETE_URI = "communication/delete/"
  static MESSAGE_GET_BY_ID = "communication/message/"
  static MESSAGE_UPDATE_URI = "communication/update/"
  static NEWS_POST_URI = "news/add"
  static NEWS_LIST_URI = "news/all"
  static NEWS_GET_INFO_URI = "news/details/"
  static NEWS_DELETE_URI = "news/delete/"
  static NEWS_UPDATE_URI = "news/update/"

  //PRIVACY OLD
  static PRIVACY_TEXT_1_ADD_URI = "privacy_1/insert"
  static PRIVACY_TEXT_2_ADD_URI = "privacy_2/insert"
  static PRIVACY_TEXT_3_ADD_URI = "privacy_3/insert"
  static PRIVACY_TEXT_4_ADD_URI = "privacy_4/insert"
  static PRIVACY_TEXT_5_ADD_URI = "privacy_5/insert"

  //PRIVACY NEW
  static PRIVACY_1_TEXT_INSERT_URI = "privacy/insert_privacy_text_1"
  static PRIVACY_2_TEXT_INSERT_URI = "privacy/insert_privacy_text_2"
  static PRIVACY_3_TEXT_INSERT_URI = "privacy/insert_privacy_text_3"
  static PRIVACY_4_TEXT_INSERT_URI = "privacy/insert_privacy_text_4"
  static PRIVACY_5_TEXT_INSERT_URI = "privacy/insert_privacy_text_5"
  static GET_ALL_PRIVACY_URI = "privacy/get_all_privacy_text"
  static GET_PRIVACY_LIST_URI = "privacy/get_privacy_list"
  static GET_USER_LIST_OF_PRIVACY_AGG = "privacy/get_user_list"


  static PRIVACY_ADD_UPDATE_URI = "privacy/update"
  static PRIVACY_VIEW_URI = "privacy"
  static PRIVACY_DELETE_URI = "privacy/delete"

  static GET_USERS_EXPORT_STATUS = "show_user_export_excel_button/";
  static ALL_USERS_INFO_EXPORT = "export/all_prv_a_users";

  static NOTICE_ADD_UPDATE_URI = "notice/update"
  static NOTICE_VIEW_URI = "notice/all"
  static NOTICE_DELETE_URI = "notice/delete"

  static REVIEW_QUESTIONS_POST_URL = "reviews_question/create"
  static REVIEW_SET_INFO_URI = "reviews_set/list"
  static REVIEW_QUESTIONS_WITH_SET_URI = "reviews/details/"
  static REVIEW_QUESTIONS_UPDATE_POST_URL = "reviews/update/"
  static REVIEW_ACTIVE_STATUS_URI = "reviews/current_active/"
  static REVIEW_SET_DELETE_URI = "review_set/delete/"

  static CONTACT_UPDATE_URI = "contact/update/"
  static CONTACT_LIST_URI = "contact"
  static CONTACT_SPECIFIC_URI = "contact/"
  static CONTACT_DELETE_URI = "contact/delete/"

  static FLYER_CREATE_URI = "flyer/add";
  static FLYER_UPDATE_URI = "flyer/update/";
  static FLYER_DELETE_URI = "flyer/delete/";
  static FLYER_LIST_URI = "flyer/all";
  static FLYER_GET_INFO_URI = "flyer/info/";

  static UPLOAD_BRANCH_INFO_URI = "sale_point_list/upload_excel";
  static UPLOAD_BRANCH_SERVICE_URI = "sale_point_list/upload_service_excel";
  static GET_BRANCHES_LIST_URI = "sale_point_list/all";
  static BRANCH_DELETE_URI = "sale_point_list/";
  static BRANCH_EXISTS_URI = "sale_point/exists";
  static SINGLE_BRANCH_INFO_URI = "sale_point_list/";
  static PRODUCT_LIST_SEARCH_URI = "product/product_list_search";
  static GET_PRODUCT_UPLOAD_STATUS = "show_product_upload_button";
  static GET_PRODUCT_EXPORT_STATUS = "show_product_export_excel_export_button";
  static EXCEL_PRODUCT_INFO_URI = "product/upload_excel";
  static DELETE_ALL_PRODUCT_IMAGES = "product/delete_all_product_images";
  static ALL_PRODUCT_INFO_EXPORT = "product/export_all_products";
  static PRODUCT_LIST_URI = "product/all";
  static SINGLE_EXCEL_PRODUCT_INFO = "product/info/";
  static EXCEL_PRODUCT_UPDATE_URI = "product/update/";
  static PRODUCT_POINTS_UPDATE_URI = "product_point_update/";
  static EXCEL_PRODUCT_DELETE_URI = "product/delete/";
  static EXCEL_PRODUCT_ZIP_IMAGE_URI = "product/zip_image_upload"
  static GET_PRODUCT_IMAGES = "product/images";
  static PRODUCT_IMAGE_NAME_UPDATE = "product/image_name_update";
  static PRODUCT_IMAGE_DELETE_URI = "product/image_delete";
  static UPLOAD_PRODUCT_IMAGE_URI = "product/upload_product_image";
  static REGION_LIST_URI = "region/all"
  static PROVINCE_LIST_URI = "province/all/"
  static COMUNE_LIST_URI = "comune/all/"
  static UPDATE_BRANCH_INFO = "sale_point_list/update/"
  static UPDATE_BRANCH_SERVICE_INFO = "sale_point_list/update_service/"
  static TICKET_LIST_SEARCH_URI = "ticket_management/search_ticket_list";
  static TICKETS_LIST_URL = "ticket_management/all_ticket"
  static TICKETS_UNSEEN_COUNT_URL = "ticket_management/count_unseen_tickets"
  static SINGLE_TICKET_INFO_URI = "ticket_management/single_ticket/"
  static SEND_ADMIN_REPLIED_TEXT_URL = "ticket_management/create_reply/";
  static TICKET_STATUS_CHANGE_URL = "ticket_management/ticket_status_change/";
  static ALL_UNSEEN_MESSAGES_TICKETS = "ticket_management/get_all_unseen_replies_tickets";
  static ALL_UNSEEN_TICKETS = "ticket_management/get_all_unseen_tickets";
  static GET_ALL_TICKET_CATEGORIES = "ticket_categories_list";
  static POST_TICKET_CATEGORY_UPDATE = "ticket_category_update";
  static PRODUCT_MEDIA_INFO_POST_URI = "product_info/add";
  static PRODUCT_INFO_LIST_SEARCH_URI = "product_info/list_search"
  static PRODUCT_INFO_ALL_URI = "product_info/all";
  static PRODUCT_INFO_DETAILS_URI = "product_info/details/";
  static PRODUCT_INFO_DELETE_URI = "product_info/delete/";
  static PRODUCT_INFO_UPDATE_URI = "product_info/update/";
  static GET_ALL_LAST_TIME_OFFERS_URI = "last_minute_offer/all";
  static GET_SINGLE_LAST_TIME_OFFER_URI = "last_minute_offer/details/";
  static LAST_MINUTE_OFFER_CREATE_URI = "last_minute_offer/add";
  static DELETE_LAST_MINUTE_OFFER_URI = "last_minute_offer/delete/";
  static GET_SALES_POINTS_FOR_OFFERS_URI = "sales_points_for_offers/all";
  static LAST_MINUTE_OFFER_UPDATE_URI = "last_minute_offer/update";
  static SURVEY_SET_CREATE_URI = "advance_profiling/create_set";
  static ADVANCE_PROFILING_LIST_URI = "advance_profiling/all_set";
  static ADVANCE_PROFILING_SINGLE_GET_URI = "advance_profiling/single_set/";
  static ADVANCE_PROFILING_SET_NAME_UPDATE_URI = "advance_profiling/update_set/";
  static ADVANCE_PROFILING_DELETE_URI = "advance_profiling/delete_set/";
  static ADVANCE_PROFILING_CREATE_QUESTIONS_URI = "advance_profiling/create_question";
  static ADVANCE_PROFILING_UPDATE_QUESTION_URI = "advance_profiling/update_question/";
  static ADVANCE_PROFILING_DELETE_QUESTION_URI = "advance_profiling/delete_question/";
  static ADVANCE_PROFILING_SET_ACTV_DEACT_URI = "advance_profiling/activate_set/";
  static GET_SINGLE_SET_PARTICIPATION = "advance_profiling/single_set_participation/";
  static SHOW_SURVEY_PARTICIPANTS_EXPORT_BUTTON = "show_survey_participents_excel_export_button/";
  static EXPORT_SURVEY_SET_PARTICIPANTS = "export_survey_set_participents";

  static ALL_SALES_POINT_LIST = "all_sales_point_list";
  static ADD_DELIVERY_SLOT_SPECIAL_DATES = "add_delivery_slot_special_dates";
  static UPLOAD_DELIVERY_SLOT_EXCEL_FILE = "upload_delivery_slot_excel_file";

  static GET_USER_ACCOUNT_DELETE_REQUEST = 'account_delete_requests/list';
  static SEARCH_USER_ACCOUNT_DELETE_REQUEST = 'account_delete_requests/user-search';

  static GET_SETTING_INFO = "settings/all";
  static POST_SETTING_INFO = "settings/update";
  static CHECK_PAYMENT_KEY_EXISTS_URL = "settings/payment_key_exist";
  static POST_STRIPE_PAYMENT_KEY = "settings/post_stripe_payment_key";
  static POST_PAYPAL_PAYMENT_KEY = "settings/post_paypal_payment_key";
  static POST_ALL_PAYMENT_KEY = "settings/post_all_payment_key";
  static GET_ADMIN_EMAIL_URI = "settings/get_admin_email";
  static SET_ADMIN_EMAIL_URI = "settings/set_admin_email";
  static GET_REFERRAL_SETTINGS = "referral_settings";
  static UPDATE_REFERRAL_SETTINGS = "referral_settings_update";

  static PRODUCT_ORDER_SEACRH_URI = "order_list/search";
  static ALL_PRODUCT_ORDER_URI = "order_details/all";
  static GET_ORDER_LIST_FOR_NEW_MESSAGE = "order_list_for_new_chat/all";
  static SINGLE_PRODUCT_ORDER_URI = "order_details/order/"; 
  static PRODUCT_ORDER_EXPORT = "export_all_orders"; 
  static ORDER_PRODUCT_UPDATE_URI = "order_details/update/";
  static ORDER_PRODUCT_REMOVE_URI = "order_details/delete_request/"
  static ORDER_STATUS_CHANGED_URI = "order_details/update_status/"
  static ORDER_NEW_PRODUCT_URI = "order_details/add_request/"
  static ORDER_DELIVERY_AVAILABLE_SLOT_URI = "handle_slot/"
  static ORDER_DELIVERY_SLOT_UPDATE_URI = "update_order_with_slot/"
  static PRODUCT_ORDER_CONFIRM_URI = "confirm_product_order/";
  static SEND_ORDER_CHAT_MESSAGE = "send_chat_msg_for_order";
  static GET_ORDER_CHAT_MESSAGE = "get_chat_msg_for_order/"
  static GET_ALL_ORDER_STATUS_URI = "order_details/status/all";
  static SUBMIT_COUPONS_IDS = "order_details/add_coupons/";
  static GET_ORDER_EXPORT_STATUS = "show_order_export_excel_export_button";
  static GET_TOTAL_UNSEEN_CHAT = "get_total_unseen_chat";
  static GET_NEW_TOTAL_ORDERS = "get_new_total_orders";
  static GET_TOTAL_UNSEEN_ORDERS = "get_all_new_order_list";
  static ORDER_MOVEMENT = "order_movement";
  static ORDER_EXTRA_COST_CALCULATION = "order_extra_cost_calculation";

  static FIDELITY_CARD_REQ_SEARCH_URI = "fidelity_card_requests/search";
  static FIDELITY_CARD_REQUESTS_LIST_URI = "get_all_fidelity_card_requests";
  static FIDELITY_CARD_REQUESTS_DETAILS_URI = "get_fidelity_card_requests_details/";
  static UPDATE_FIDELITY_CARD_NUMBER_URI = "update_fidelity_card_number";
  static TOTAL_NEW_FIDELITY_CARD_REQ_URI = "total_unseen_fidelity_cards";
  static UNSEEN_FIDELITY_CARD_REQUESTS_LIST_URI = "get_all_fidelity_card_requests_new";
  static EXPORT_ALL_FIDELITY_LIST = "export_all_fidelity_list";
  static GET_CARD_EXPORT_STATUS = "show_fidelity_list_export_button";
  static FIDELITY_CARD_UPDATE_STATUS = "fidelity_card_update_status";

  static UPLOAD_FIDELITY_CARD_URI = "upload_fidelity_card_excel";
  static FIDELITY_CARD_LIST_URI = "fidelity_card_list";
  static FIDELITY_CARD_DETAILS_URI = "single_fidelity_card_details/";
  static FIDELITY_CARD_UPLOAD_BUTTON = "show_fidelity_upload_button";
  static FIDELITY_CARD_SEARCH_URI = "fidelity_card_search_list";
  static USER_VERIFICATION = "user/verification";

  static UPLOAD_COUPON_URI = "upload_coupon_excel";
  static COUPON_LIST_URI = "coupon_list";
  static COUPON_UPLOAD_BUTTON = "show_coupon_excel_upload_button";
  static COUPON_SEARCH_URI = "coupon_search_list";
  static GET_COUPON_EXPORT_STATUS = "show_coupon_export_excel_export_button";
  static ALL_COUPONS_EXPORT = "coupon/export_all_coupons";
  static RESET_ALL_COUPONS = "reset_all_coupons"; 


  static GET_LOGIN_URI = "login_info/";
  static CHECK_OLD_PASSWORD_URI = "check_old_password";
  static POST_SECURITY_QUESTIONS_URI = "security_question_submit";
  static GET_SECURITY_QUESTIONS_URI = "get_login_security_questions";
  static REGISTRATION_URI = "registration_uri"

  static all_help_various_list = "help_various_list";

  static GET_USER_POINTS_LIST = "user_points_list"; 
  static GET_USER_POINTS_DETAILS = "user_points_details/";
  static GET_USER_POINTS_CONVERSION_REQUESTS = "point_conversion/request/list";
  static USER_POINTS_CONVERSION_REQUESTS_SEARCH = "point_conversion/request/list/search";
  static USER_POINT_SEARCH_BY_ID ="user_points_search_by_id";
  static USER_POINTS_APPROVED_ALL = "settings/points/approve-all";
  static USER_POINTS_REJECT_ALL = "settings/points/reject-all";  
  static USER_POINTS_STATUS_CHANGE = "settings/points/status-change/"; 
  static STORE_TARGET_POINTS = "store_target_points";
  static SHOW_TARGET_POINTS = "show_target_points";
  static GET_USER_REFERRAL_POINTS_LIST ="referral_users_points_list";
  static SHOW_REFERRAL_USER_DETAILS = "referral_users_points_details/";
  static GET_PRODUCTS_POINTS_LIST = "products_points_list";
  static SEARCH_PRODUCTS_POINTS_LIST = "product_point_list_search";
  static GET_USER_POINT_EXPORT_STATUS = "show_user_point_excel_export_button";
  static EXPORT_USER_POINTS = "export_user_points";

  static GET_POINTS_CONVERSION_DETAILS ="settings/points/conversion/details";
  static POST_POINT_CONVERSION_RATE ="settings/points/conversion/rate";
  static POST_POINT_CONVERSION_MIN_MAX ="settings/points/conversion/min-max";
  static POST_POINT_CONVERSION_EXPIRY_DATE ="settings/points/conversion/expirydate";

  static GET_PRODUCTS_ATTR_NAMES = "settings/product/attributes";
  static UPDATE_PRODUCTS_ATTR_NAMES = "settings/product/attributes/update";

  static SEND_MAIL_UNVERIFIED_USERS = "send/mail/unverified-users";
  static IS_ALL_MAIL_SENT = "is_all_mails_sent";


  static BRANCH_LIST = "branch/list";
  static BRANCH_USER_LIST = "branch/user/list";
  static BRANCH_USER_CREATE = "branch/user/create";
  static BRANCH_USER_DETAIL = "branch/user/detail/";
  static BRANCH_USER_UPDATE = "branch/user/update/";
  static BRANCH_USER_PASSWORD_CHANGE = "branch/user/password-change/";


  //DELIVERY_COST
  static GET_COMMON_SHIPPING_COST_URI = "get_common_shipping_cost";
  static SUBMIT_COMMON_SHIPPING_COST_URI = "submit_common_shipping_cost";
  static submit_manually_shipping_cost_add_update = "manually_shipping_cost_add_update";
  static GET_SHIPPING_COST_LIST = "get_shipping_cost_list";
  static GET_ALL_REGION_LIST = "region/all";
  static GET_ALL_PROVINCE_LIST = "get_province_by_region/";
  static GET_ALL_MUNICIPALITY_LIST = "get_municipality_by_province_id/";
  static GET_ALL_SALES_POINT_LIST = "sales_points_all";
  static SEARCH_FROM_SHIPPING_LIST = "search_from_shipping_cost_list";
  static UPLOAD_DELIVERY_COST_EXCEL_FILE = "upload_delivery_cost_excel_file";
  static SHOW_COUPON_EXCEL_UPLOAD_BUTTON = "show_coupon_excel_upload_button";
  static SHOW_DELIVERY_COST_EXCEL_UPLOAD_BUTTON = "show_delivery_cost_excel_upload_button";

  //SHOPING POINTS
  static GET_SHOPPING_POINTS_URI = 'get_shopping_points';
  static SUBMIT_SHOPPING_POINTS_URI = 'submit_shopping_points'; 

  //forgot passowrd
  static GET_SECURITY_QUESTIONS_ONLY_URI = "get_security_question_only";
  static POST_SECURITY_QUESTIONS_ANSWER_URI = "check_answers_for_password";
  static POST_NEW_PASSWORD_URI = "set_new_passowrd"

  //bannners
  static BANNER_IMAGES_POST_URI = "banners_images_upload";
  static GET_BANNERS_INFO_URI = "get_banners_info";
  static CATEGORY_IMAGES_POST_URI = "category_images_upload" 
  static GET_CATEGORY_INFO_URI = "get_catg_info";

  //categories
  static GET_CATEGORIES_INFO = 'get_category_info';
  static SEARCH_CATEGORY_NAME_URI = 'search_category_name';
  static SUBMIT_CATEGORY_IMAGE_URI = 'submit_category_image';

  static LOGOUT_URI = "logout"
  

  //MSG
  static SUCCESSFULLY_DONE = "Successfully Done";
  static DELIVERY_SLOT_SELECT_STORE = "Please select one or multiple store from your store list";
  static DELIVERY_SLOT_ADD_DATES = "Please add one or multiple dates";
  static CHOOSE_DELIVERY_AVAILABILITY = "Choose delivery availability for home delivery and delivery from store";
}